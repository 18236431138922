import React from "react";
import Layout from "../styles/Layout";
import StyledTitle from "../styles/StyledTitle";

function page404() {
  return (
    <Layout>
      <StyledTitle>Page 404</StyledTitle>
    </Layout>
  );
}

export default page404;
